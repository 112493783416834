export const getStringDistance = (s1, s2, caseInsensitive = true) => {
	if (caseInsensitive) {
		s1 = s1.toLowerCase();
		s2 = s2.toLowerCase();
	}

	const m = findMatchingCharacters(s1, s2);
	if (m === 0) {
		return 0;
	}

	const jaroDistance = (m / s1.length + m / s2.length + (m - findTranspositions(s1, s2)) / m) / 3;
	const commonPrefix = findCommonPrefix(s1, s2);
	const winklerAdjustment = commonPrefix * 0.1 * (1 - jaroDistance);

	return jaroDistance + winklerAdjustment;
};

const findMatchingCharacters = (s1, s2) => {
	const matchWindow = Math.floor(Math.max(s1.length, s2.length) / 2) - 1;
	const matchedIndices1 = [];
	const matchedIndices2 = [];

	for (let i = 0; i < s1.length; i++) {
		const start = Math.max(0, i - matchWindow);
		const end = Math.min(s2.length, i + matchWindow + 1);

		for (let j = start; j < end; j++) {
			if (s1[i] === s2[j] && !matchedIndices2.includes(j)) {
				matchedIndices1.push(i);
				matchedIndices2.push(j);
				break;
			}
		}
	}

	return matchedIndices1.length;
};

const findTranspositions = (s1, s2) => {
	let transpositions = 0;
	const matchWindow = Math.floor(Math.max(s1.length, s2.length) / 2) - 1;

	for (let i = 0; i < s1.length; i++) {
		const start = Math.max(0, i - matchWindow);
		const end = Math.min(s2.length, i + matchWindow + 1);

		for (let j = start; j < end; j++) {
			if (s1[i] === s2[j]) {
				if (i !== j) {
					transpositions++;
				}
				break;
			}
		}
	}

	return transpositions;
};

const findCommonPrefix = (s1, s2) => {
	let commonPrefix = 0;
	const maxPrefixLength = 4;

	for (let i = 0; i < Math.min(s1.length, s2.length, maxPrefixLength); i++) {
		if (s1[i] === s2[i]) {
			commonPrefix++;
		} else {
			break;
		}
	}

	return commonPrefix;
};
